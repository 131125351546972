<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <el-row :gutter="20">
            <el-col :span="16">
              <el-form-item>
                <el-input
                  v-model="searchForm.tim_name"
                  size="medium"
                  placeholder="次卡名称"
                  clearable
                  @keyup.enter.native="getVipList"
                ></el-input>
              </el-form-item>
              <!--<el-form-item>
                <el-input v-model="searchForm.typeDesc" size="medium" placeholder="会员卡号" clearable></el-input>
              </el-form-item>-->

              <el-form-item>
                <el-button
                  @click="getVipList"
                  size="medium"
                  icon="el-icon-search"
                  >搜索</el-button
                >
              </el-form-item>
              <el-form-item>
                <el-button @click="refresh" icon="el-icon-refresh" size="medium">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8" style="text-align: right">
              <el-form-item>
                <el-button type="primary" size="medium" @click="addTimeCard" v-if="hasAuth('sys:timeCard:add')"
                  >创建次卡</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      v-loading="isLoading"
      tooltip-effect="dark"
      border
      style="width: 100%"
      stripe
      size="small"
      :row-style="{ height: '10px' }"
      :cell-style="{ padding: '1px 0' }"
      @selection-change="handleSelectionChange"
    >
      <!--<el-table-column type="selection" width="55" align="center"></el-table-column>-->
      <el-table-column type="index" label="序号" width="60" :index="indexMethod" align="center" />
      <el-table-column
        prop="cou_title"
        label="次卡名称"
        align="center"
      ></el-table-column>
      <el-table-column label="有效期" width="120" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.cou_valid_date_type === '0'">
            领取后{{ scope.row.cou_valid_date_day }}天
          </span>
          <span v-else-if="scope.row.cou_valid_date_type === '1'">
            {{ scope.row.cou_valid_date_range.replace(",", "至") }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="syzs"
        label="剩余张数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="rec_counts"
        label="领取张数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="hxcs"
        label="核销次数"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="al_name"
        label="所属商盟"
        align="center"
      ></el-table-column
      >
      <el-table-column
        prop="me_name"
        label="所属商户"
        align="center"
      ></el-table-column
      >
       <el-table-column
        prop="soe_name"
        label="所属门店"
        align="center"
      ></el-table-column
      >
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span>
            <el-tag
              type="success"
              size="small"
              effect="dark"
              v-if="scope.row.tim_status === '0'"
              >上架</el-tag
            >
            <el-tag
              type="warning"
              size="small"
              effect="dark"
              v-if="scope.row.tim_status === '1'"
              >下架</el-tag
            >
          </span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="150" align="center">
        <template slot-scope="scope">
          {{scope.row.cou_created ? scope.row.cou_created.replaceAll('T', ' ') : '' }}
        </template>
      </el-table-column>
      <el-table-column prop="icon" width="200px" label="操作" align="center">
        <template slot-scope="scope">
          <!--<el-button
            :disabled="scope.row.tim_status !== '0'"
            type="text"
            @click="lqjck(scope.row)"
            >领取</el-button
          >
          <el-divider direction="vertical"></el-divider>-->
          <el-button
            :disabled="scope.row.tim_status !== '0'"
            type="text"
            @click="edit(scope.row)"
            v-if="hasAuth('sys:timeCard:edit')"
            >修改</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button
            :disabled="scope.row.tim_status !== '0'"
            type="text"
            @click="soldOutCard(scope.row.tim_id, scope.row.cou_title)"
            v-if="hasAuth('sys:timeCard:delete')"
            >下架</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button type="text" @click="lqmx(scope.row)">
            <span style="color: #67C23A;">
              领取明细
            </span>
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="'领取次卡-'+timeCardName"
      :visible.sync="qrcodeDialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <div style="padding-left: 80px">
        <div id="qrcodeImg"></div>
        <div style="width: 200px; margin-top: 20px">
          <el-button type="text" @click="copyQrcodeLink">复制链接</el-button>
          <el-button type="text" style="float: right" @click="downloadQrcode"
            >点击下载</el-button
          >
          <a id="downloadImg"></a>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              qrcodeDialogVisible = false;
            }
          "
          >取消</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/timeCard";
import QRCode from "qrcodejs2";
export default {
  name: "timeCardList",
  data() {
    return {
      timeCardName: '',
      qrcodeDialogVisible: false,
      timeCardLink: "",
      isLoading: false,
      searchForm: {
        tim_name: "",
      },
      total: 10,
      size: 10,
      current: 1,
      tableData: [],
      dialogVisible: false,
      multipleSelection: [],
    };
  },
  created() {
    this.getVipList();
  },
  methods: {
    indexMethod(index) {
      index = (index + 1) + (this.current - 1) * this.size
      return index
    },
    downloadQrcode() {
      let img = document
        .getElementById("qrcodeImg")
        .getElementsByTagName("img")[0];
      // 构建画布
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.getContext("2d").drawImage(img, 0, 0);
      // 构造url
      let url = canvas.toDataURL("image/png");
      document.getElementById("downloadImg").setAttribute("href", url);
      document
        .getElementById("downloadImg")
        .setAttribute("download", "领取次卡.png");
      document.getElementById("downloadImg").click();
    },
    copyQrcodeLink() {
      var input = document.createElement("input"); // 创建input对象
      input.value = this.timeCardLink; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message.success("复制成功！");
    },
    edit(row) {
      console.log(row);
      this.$router.push({
        path: "/timeCard/createTimeCard",
        query: {
          couId: row.cou_id,
          timId: row.tim_id,
          couBatch:row.cou_batch
        },
      });
    },
    lqjck(row) {
      //领取计次卡
      this.timeCardName = row.cou_title;
      api.getTimeCardLink(row.cou_id).then((res) => {
        this.qrcodeDialogVisible = true;
        this.$nextTick(function () {
          document.getElementById("qrcodeImg").innerHTML = "<span></span>";
          let qrcode = new QRCode("qrcodeImg", {
            width: 200,
            height: 200,
            text: res.data.message,
            render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            colorDark: "#000", // 背景色
            colorLight: "#fff", // 前景色
          });
        });
        this.timeCardLink = res.data.message;
      });
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    refresh() {
      this.searchForm = {};
      this.current = 1;
      this.getVipList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    search() {
      this.current = 1;
      this.getVipList();
    },
    getVipList() {
      this.isLoading = true;
      api
        .list({
          params: {
            tim_name: this.searchForm.tim_name,
            current: this.current,
            size: this.size,
          },
        })
        .then((res) => {
          this.tableData = res.data.data.result.records;
          this.size = res.data.data.result.size;
          this.current = res.data.data.result.current;
          this.total = res.data.data.result.total;
          this.isLoading = false;
        });
    },
    addTimeCard() {
      this.$router.push({ path: "/timeCard/createTimeCard" });
    },
    soldOutCard(timId, cou_title) {
      this.$confirm(
        "下架后不可再次领取，此操作后不可恢复，是否确认下架"+cou_title+"?",
        "请确认",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          api.soldOutCardById(timId).then((res) => {
            if (res.data.code === 200) {
              this.$notify({
                title: "操作成功",
                message: "",
                position: "bottom-right",
                type: "success",
              });
              this.getVipList();
            } else {
              this.$notify({
                title: "prefect",
                message: "操作失败",
                type: "error",
                position: "bottom-right",
              });
            }
          });
        })
        .catch(() => {
          //取消
        });
    },
    cancelTf(formName) {
      this.resetForm(formName);
      this.tfForm = {};
      this.tfCardDialogVisible = false;
    },
    resetForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    handleSizeChange(val) {
      this.size = val;
      this.getVipList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getVipList();
    },
    lqmx(row) {
      this.$router.push({
        path: "/timeCard/cardRoll",
        query: {
          couBatch: row.cou_batch,
          type: 'second'
        }
      });

    },
  },
};
</script>

<style scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.card-contanier {
  margin-bottom: 5px;
}
.tf-card-hint {
  color: #999;
  margin-top: 5px;
  margin-left: 25px;
}
.tf-card-radio {
  margin-top: 15px;
}
</style>
